import jQuery from 'jquery';

export default class ModalContentToggle {
    constructor() {
        this.modalContentToggleItem = jQuery('.modal-content-toggle');
        this.init();
    }

    init() {
        this.modalContentToggleItem.on('click', (el)=> {
            const dataId = jQuery(el.target).data('id');
            const modalCont = jQuery('#' + dataId);
            modalCont.show();
            modalCont.siblings('.modal-content').hide();
        })
    }
}