import jQuery from 'jquery';
import slick from 'slick-carousel';

export default class MainScripts {
    constructor() {
        this.slider = jQuery('.homepage-banners-slides');
        this.init();
        this.homeSlider();
    }

    init() {
        const headerSkipContent = jQuery('.header-right .skip-content');
        const windowHeight = jQuery(window).height();

        jQuery('.mobile-menu-icon').on('click', function () {
            headerSkipContent.height(windowHeight);
            if (!jQuery(this).hasClass('skip-active')) {
                headerSkipContent.height('');
            }
        });

        jQuery('.close-nav').on('click', function () {
            headerSkipContent.height('').removeClass('skip-active');
            jQuery('.mobile-menu-icon').removeClass('skip-active');
        });

    }

    homeSlider() {
        const slider = this.slider;
        if (slider.length) {
            slider.slick({
                arrows: false,
                dots: true,
                infinite: true,
                autoplay: true
            });
        }
    }
};

