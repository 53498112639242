import jQuery from 'jquery';

export default class CustomSelect {
    constructor() {
        this.customSelectDropDown = jQuery('.dropdown-select');
        this.customSelect = this.customSelectDropDown.find('.dropdown-item');
        this.init();
    }

    init() {
        this.customSelect.on('click', (el)=> {
            const val = jQuery(el.target).html();
            const ariaLabelledby = jQuery(el.target).parent().attr('aria-labelledby');
            const dropDownMenu = jQuery('#' + ariaLabelledby);
            const dataId = dropDownMenu.data('id');
            dropDownMenu.html(val);
            jQuery('#' + dataId).val(val);
        })
    }
}