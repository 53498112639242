import jQuery from 'jquery';
// import PinchZoom from "pinch-zoom-js";

export default class PinchZoomGallery {
    constructor() {
        this.galleryGridItems = jQuery('.gallery-grid .gallery-item');
        this.galleryImageClass = '.gallery-script-img';
        this.zoomModal = jQuery('#pinchZoom');
        this.zoomModalClonedThumb = jQuery('#pinchZoom .cloned-thumb');
        this.zoomModalContent = jQuery('#pinchZoom .modal-content');
        this.zoomModalBody = jQuery('#pinchZoom .modal-body');
        this.mainImageZoomed = jQuery('#main-image-zoomed');
        this.galleryBlockOffset = jQuery('.gallery-block').offset().top;
        let {getScreenHeight, getImageHeight, centerScreen, centerPosImage, moveImage, animation, clonedImages} = '';
        this.init();
    }

    init() {
        const self = this;
        jQuery(document).on('click', self.galleryImageClass, (item)=> {
            const zoomImage = jQuery(item.target).data('zoom-image');
            const zoomCounter = jQuery(item.target).data('counter');
            self.zoomModal.modal('show');
            document.querySelector('html').style.position = 'fixed';
            setTimeout(self.fullscreenImageZoom(zoomImage), 400);
            self.createThumb(zoomCounter);

        })
    }

    constantsMath() {
        const self = this;
        const e = self.mainImageZoomed;
        self.getImageHeight = e.height();
        self.getScreenHeight = (jQuery(window).height());
        self.centerScreen = self.getScreenHeight / 2;
        self.y = self.zoomModal.offset();
        self.centerPosImage = (self.getImageHeight - self.getScreenHeight) / 2;

        self.getImageWidth = e.width();
        self.getScreenWidth = jQuery(window).width();
        self.centerScreenWidth = self.getScreenWidth / 2;
        self.x = self.zoomModal.offset();
        self.centerPosImageX = (self.getImageWidth - self.getScreenWidth) / 2;
    }

    fullscreenImageZoom(zoomItem){
        const self = this;
        const zoomHeight = jQuery(window).height();
        self.zoomModalContent.css({ 'top':0, 'height': zoomHeight });
        self.mainImageZoomed.attr('src', '');
        self.mainImageZoomed.hide();

        jQuery('<img src="'+ zoomItem +'">').on('load', e => {
            if(e.currentTarget.complete){
                self.mainImageZoomed.attr('src', zoomItem);
                self.mainImageZoomed.fadeIn("slow");
                self.createZoom();
            }
        });
    }

    createThumb(dataCounter){
        const self = this;
        self.zoomModalClonedThumb.find('img').off('click');
        self.zoomModalClonedThumb.empty();

        self.galleryGridItems.find('img').each(( i, e ) => {

            self.clonedImages = jQuery(e).clone();
            self.clonedImages.attr('src', e.dataset.thumb);
            self.zoomModalClonedThumb.append(this.clonedImages);

        });

        self.zoomModalClonedThumb.find('img[data-counter='+dataCounter+']').addClass("selected");
        // self.zoomModalClonedThumb.find('img[data-counter='+dataCounter+']').trigger('click');

        self.zoomModalClonedThumb.find('img').on('click', e => {
            self.zoomModalBody.find('img').attr('src', e.currentTarget.dataset.zoom);
            jQuery(e.currentTarget).addClass('selected').siblings().removeClass('selected');
        });

    }

    createZoom(){
        const self = this;
        self.constantsMath();
        self.zoomHandler = e => {
            if(!Detection.isTouchDevice()) {
                self.moveImageY = (-self.centerPosImage * (e.pageY - self.y.top)) / self.centerScreen;
                self.moveImageX = -(-self.centerPosImageX * (e.pageX - self.x.top)) / self.centerScreenWidth;
            }else{
                self.moveImageY = -(-self.centerPosImage * e.originalEvent.touches[0].pageY) / self.centerScreen;
                self.moveImageX = (-self.centerPosImageX * e.originalEvent.touches[0].pageX) / self.centerScreenWidth;
            }

            self.moveImageY = parseInt(self.moveImageY);
            self.moveImageX = parseInt(self.moveImageX);

            if(!Detection.isTouchDevice()) {
                e.currentTarget.style.transform = 'translate3d(0,'+self.moveImageY+'px,0)';
            }else{
                e.currentTarget.style.margin = '' + self.moveImageY+'px '+self.moveImageX+'px';

            }
        };

        self.mainImageZoomed.on({
            mousemove: self.zoomHandler,
            touchmove: self.zoomHandler,
            click: () => {
                self.zoomModal.modal('hide');
                document.querySelector('html').removeAttribute('style');
                jQuery('html, body').animate({scrollTop: self.galleryBlockOffset}, 0);
            }
        });
        self.zoomModal.find('.close').on('click', () => {
            document.querySelector('html').removeAttribute('style');
            jQuery('html, body').animate({scrollTop: self.galleryBlockOffset}, 0);
        });
    }
}