import jQuery from 'jquery';

const Detection = {

    _mediaQueries: ['mobile', 'tablet', 'desktop', 'xs', 'sm', 'md', 'lg', 'xl'],

    isTouchDevice: () => {
        return (
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
            navigator.userAgent.match(/Opera Mini/i) ||
            navigator.userAgent.match(/IEMobile/i)
        );
    },
    testClass: classToTest => {
        return jQuery('body').hasClass( classToTest );
    },
    browserUse: () => {
        if (Detection.isFirefox()) {
            return 'firefox';
        } else if (Detection.isEdge()) {
            return 'edge';
        } else if (Detection.isChrome()) {
            return 'chrome';
        } else if (Detection.isIe()) {
            return 'ie';
        } else if (Detection.isSafari()) {
            return 'safari';
        }
    },
    isIosDevice: () => {
        return (navigator.userAgent.match(/iPhone|iPad|iPod/i));
    },
    isOsx: () => {
        return (navigator.userAgent.match(/OS X/i));
    },
    isAWRMA: () => {
        return Detection.testClass("aw-rma");
    },
    isAWRMAGUEST: () => {
        return Detection.testClass("awrma-guest");
    },
    isFirefox: () => {
        return navigator.userAgent.match(/Firefox/i);
    },
    isChrome: () => {
        return navigator.userAgent.match(/Chrome/i);
    },
    isIe: () => {
        return navigator.userAgent.match(/Trident/i);
    },
    isEdge: () => {
        return navigator.userAgent.match(/Edge/i);
    },
    isSafari: () => {
        const ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') != -1) {
            if (!ua.indexOf('chrome') > -1) {
                return true;
            }
        }
    },
    isMobileDevice: () => {
        return (navigator.userAgent.match(/Mobile/i));
    },
    isHome: () => {
        return Detection.testClass('cms-index-index');
    },
    isListing: () => {
        return Detection.testClass('catalog-category-view');
    },
    isLogged: () => {
        return Detection.testClass('logged');
    },
    isProductView: () => {
        return Detection.testClass('catalog-product-view');
    },
    isCart: () => {
        return Detection.testClass('checkout-cart-index');
    },
    isCheckout: () => {
        // for retro-compatibility: keep checkout-cart-index here, but use "isCart" for real cart page
        const isCheckoutOnePage = Detection.testClass('checkout-onepage-index');
        const isOneStepCheckoutPage = Detection.testClass('onestepcheckout-index-index');
        const isCartPage = Detection.testClass('checkout-cart-index');

        return isCheckoutOnePage || isOneStepCheckoutPage || isCartPage;
    },
    isSearch: () => {
        return Detection.testClass('catalogsearch-result-index');
    },
    isMyAccount: () => {
        return Detection.testClass('all-account-pages');
    },
    isCmsPages: () => {
        return Detection.testClass("cms-page-view");
    },
    isHDPI: () => {
        return Detection._mM && Detection._mM.matches;
    },
    isIPadPro: () => {
        return (Detection._mM && Detection._mM.matches) && (window.innerWidth > 1023) && navigator.userAgent.match(/iPad/i);
    },
    isInBreakpoint: breakpoint => {
        return ( ~$.inArray( breakpoint, Detection._mediaQueries ) ) && Detection._testBreakpoint( breakpoint );
    },
    _testBreakpoint: breakpoint => {
        return jQuery('#breakpoint-tester').find('[data-breakpoint-test="'+ breakpoint +'"]').is(':visible');
    },
    print: () => {
    window.print();
    }

};

export default Detection;