import MainScripts from "./partial/page/home";
import CustomSelect from "./partial/form/select";
import ModalContentToggle from "./partial/modal/modal-content-toggle";
import PinchZoomGallery from "./partial/product/zoom";
import Detection from "./partial/detection";
window.Detection = Detection;

import 'bootstrap';

document.addEventListener('DOMContentLoaded', () => {
    new MainScripts();
    new CustomSelect();
    new ModalContentToggle();

    if (Detection.isProductView()) {
        new PinchZoomGallery();
    }
});